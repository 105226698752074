import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';
import { BrowserRouter, Route, Routes} from "react-router-dom";
import Navbarcomp from './components/Navbarcomp';

import Home from './views/Home';
import Signup from './views/Signup';
import Login from './views/Login';
import Error404 from './views/Error404';
import Aboutme from './views/Aboutme';
import Works from './views/Works';

import AboutmeEN from './views/AboutmeEN';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/*Dont ask why not nested route with outlet. Actaally preventing calling the navbar bar everytime main page(Home) is called*/}
    <BrowserRouter>
      <Navbarcomp/>
      <Routes>
        <Route path="/" exact element={<Home />} />\
        <Route path="/login" exact element={<Login />} />
        <Route path="/signup" exact element={<Signup />} />
        <Route path="/404" exact element={<Error404 />} />
        <Route path="/aboutme" element={<Aboutme />} />
        <Route path="/works" element={<Works />} />
        <Route path="/AboutmeEN" element={<AboutmeEN />} />
        <Route path="*" element={<Error404 />} />
        {/*<Route path="*" element={<Navigate to ="/404" />}/>*/}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);