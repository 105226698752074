import React, { Component, createRef } from 'react'
import { Card, Form, Button, Alert } from 'react-bootstrap'
import '../assets/css/Login.css'
import { Link } from "react-router-dom";

export class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      setError: ""
    }

    this.emailRef = createRef()
    this.passwordRef = createRef()
  }

  render() {
    return (
      <>
        <div className='d-flex align-items-center justify-content-center bgpagelogin'>
          <div className='logindivcard'>
            <Card>
              <Card.Body>
                <h2 className="text-center mb-4">Login</h2>
                {(this.state.setError !== 'logged' && this.state.setError !== '') && <Alert variant="danger">{this.state.setError}</Alert>}
                {(this.state.setError === 'logged') && <Alert variant="success">Exito</Alert>}
                <Form onSubmit={(e) => { this.handleSubmit(e) }}>
                  <Form.Group id="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" ref={this.emailRef} placeholder="demo@demo.com" required />
                  </Form.Group>
                  <Form.Group id="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" ref={this.passwordRef} placeholder="demo" required />
                  </Form.Group>
                  <div style={{ marginTop: '20px' }}>
                    <Button disabled={this.state.loading} className="w-100 mt-100" type="submit">Login</Button>
                  </div>
                </Form>
                <div className="w-100 text-center mt-4" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
                  <div>
                    <Link className='linklogin'>Forgot Password?</Link>
                  </div>
                  <div>
                    <Link className='linklogin' to="/signup">Sign Up</Link>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </>
    )
  }

  async handleSubmit(e) {
    e.preventDefault();

    if (this.passwordRef.current.value !== 'demo' || this.emailRef.current.value !== 'demo@demo.com') {
      return this.setState({ setError: 'Wrong Data' })
    }

    this.setState({ setError: 'logged' })

  }
}

export default Login