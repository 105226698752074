import React, { Component } from 'react'
import '../assets/css/AboutmeEN.css'
//import ProfilePicture from '../assets/icons/PandaFaceWinkLogo.svg'
import ProfilePicture from '../assets/images/LCCPP.jpg'
import { Link } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import { Loadingcomp } from '../components/Loadingcomp.js'

export class AboutmeEN extends Component {
  state = {
    isOpen: false,
    showLoading: false
  };

  render() {
    return (
      <>
        <section id="about" className="about homesection">
          <div className='container darkmode-aboutme' id='cvdiv'>
            {/* ======= About Me ======= */}
            <div className="about-me aboutpdbtn">
              <div className="section-title">
                <h2>About</h2>
                <p>Luis Chuy Cho</p>
              </div>
              <div className="row">
                <div className="col-lg-4 ppimgdiv" data-aos="fade-right">
                  <img loading='lazy' src={ProfilePicture} className="img-fluid" alt="" />
                </div>
                <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
                  <h3>Computer Science Engineer</h3>
                  <p className="fst-italic">
                    La Plazuela Neighborhood, Miguel de Cervantes Ave, 4th Street S, Tegucigalpa FM11101
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <ul>
                        <li><i className="bi bi-caret-right-fill" /> <strong>Birthday:</strong> <span>23 Jan 1998</span></li>
                        <li><i className="bi bi-caret-right-fill" /> <strong>Website:</strong> <span>www.luischuy.com</span></li>
                        <li><i className="bi bi-caret-right-fill" /> <strong>Phone:</strong> <span>+504 3325 7444</span></li>
                        <li><i className="bi bi-caret-right-fill" /> <strong>City:</strong> <span>Tegucigalpa, HND</span></li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <ul>
                        <li><i className="bi bi-caret-right-fill" /> <strong>Age:</strong> <span>25</span></li>
                        <li><i className="bi bi-caret-right-fill" /> <strong>Degree:</strong> <span>Bachelor</span></li>
                        <li><i className="bi bi-caret-right-fill" /> <strong>Email:</strong> <span>luischuy12345@gmail.com</span></li>
                        <li><i className="bi bi-caret-right-fill" /> <strong>Freelance:</strong> <span>Available</span></li>
                      </ul>
                    </div>
                  </div>
                  <p>
                    Graduated in Computer Science Engineering, passionate and motivated with skills in developing software, coding and logic. I'm always looking for new challenges
                    and opportunities to expand my skill set. I perform my jobs efficiently providing practical solutions for the continuous improvement. I'm always striving to
                    improve my coding practices and stay up-to-date with the latest industry trends and technologies. I'm committed to delivering high-quality, efficient,
                    and user-friendly software solutions. If you're interested in connecting or learning more about my work, please don't hesitate to reach out!
                  </p>
                </div>
              </div>
            </div>{/* End About Me */}
            {/* ======= Counts ======= */}
            <div className="counts aboutpdbtn" id='counts'>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="count-box">
                    <i className="bi bi-file-earmark-arrow-down headericon" />
                    <Link className='linknocss'><span className='borderanim' onClick={() => { this.downloadCV() }}>DL <i className="bi bi-cloud-download"></i></span></Link>
                    <p>Download CV</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                  <div className="count-box">
                    <i className="bi bi-lightbulb headericon" />
                    <Link className='linknocss'><span className='borderanim' onClick={() => { this.switchModes() }}>Switch <i className="bi bi-lamp"></i></span></Link>
                    <p>Light/Dark Mode</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                  <div className="count-box">
                    <i className="bi bi-journal-richtext headericon" />
                    <Link className='linknocss'><span className='borderanim' onClick={() => { this.openModal() }}>Go <i className="bi bi-box-arrow-right"></i></span></Link>
                    <p>Portafolio</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                  <div className="count-box">
                    <i className="bi bi-person-lines-fill headericon" />
                    <Link className='linknocss'><span className='borderanim' onClick={() => { this.openLoading() }}>Go <i className="bi bi-arrow-right"></i></span></Link>
                    <p>Contact Me</p>
                  </div>
                </div>
              </div>
            </div>{/* End Counts */}
            {/* ======= Skills  ======= */}
            <div className="skills skillspdbtm">
              <div className="section-title">
                <h2>Skills</h2>
              </div>
              <div className="row" style={{ marginTop: '40px' }}>
                <div className="col-lg-12">
                  <ul>
                    <li><i className="bi bi-caret-right-fill" /> <strong>Programming languages:</strong> <span>Java, JavaScript, Typescript, PHP, SQL, C and C++, C#</span></li>
                    <li><i className="bi bi-caret-right-fill" /> <strong>Frameworks/Libraries:</strong> <span>AngularJS, ReactJS, NodeJS, jQuery, VueJS, NextJS, Ionic, Electron, DiscordJS</span></li>
                    <li><i className="bi bi-caret-right-fill" /> <strong>Mobile Development:</strong> <span>Ionic, Cordova & Capacitor(IOS & Android)</span></li>
                    <li><i className="bi bi-caret-right-fill" /> <strong>Database management:</strong> <span>MySQL, Oracle, MongoDB, FirebaseJS</span></li>
                    <li><i className="bi bi-caret-right-fill" /> <strong>DevOps:</strong> <span>Git</span></li>
                    <li><i className="bi bi-caret-right-fill" /> <strong>Others:</strong> <span>MS Office, Adobe Illustrator, Photoshop, Premiere Pro, Corel, Google Maps/Direction Api, Google Analytics Data Api</span></li>
                  </ul>
                </div>
              </div>
            </div>{/* End Skills */}
            {/* ======= Interests ======= */}
            <div className="interests aboutpdbtn">
              <div className="section-title">
                <h2>Interests</h2>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="bi bi-boombox" style={{ color: '#ffbb2c' }} />
                    <h3>Music</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="bi bi-file-code" style={{ color: '#5578ff' }} />
                    <h3>Coding</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="bi bi-controller" style={{ color: '#e80368' }} />
                    <h3>Video Games</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4 ">
                  <div className="icon-box">
                    <i className="bi bi-film" style={{ color: '#e361ff' }} />
                    <h3>TV/Series</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="bi bi-book" style={{ color: '#47aeff' }} />
                    <h3>Reading</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="bi bi-car-front-fill" style={{ color: '#ffa76e' }} />
                    <h3>Automobiles</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="fa-solid fa-volleyball" style={{ color: '#11dbcf' }} />
                    <h3>Sports</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="fa-solid fa-kitchen-set" style={{ color: '#4233ff' }} />
                    <h3>Cooking</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="fa-solid fa-person-walking" style={{ color: '#b2904f' }} />
                    <h3>Exercising and healthcare</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="bi bi-airplane" style={{ color: '#b20969' }} />
                    <h3>Traveling</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="fa-solid fa-person-hiking" style={{ color: '#ff5828' }} />
                    <h3>Outdoor Activities</h3>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 mt-4">
                  <div className="icon-box">
                    <i className="fa-solid fa-hand-holding-hand" style={{ color: '#29cc61' }} />
                    <h3>Community Involvement</h3>
                  </div>
                </div>
              </div>
            </div>{/* End Interests */}
            {/* ======= Resume ======= */}
            <div className="resume aboutpdbtn">
              <div className="section-title">
                <h2>Resume</h2>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <h3 className="resume-title">Education</h3>
                  <div className="resume-item">
                    <h4>Bachelor of Computer Science Engineering</h4>
                    <h5>Sept 2016 - June 2021</h5>
                    <p><em>Universidad Católica de Honduras, Tegucigalpa, HND</em></p>
                    <p>Graduated from Universidad Católica de Honduras at the Sagrado Corazón de Jesús campus with a degree in computer science engineering.</p>
                  </div>
                  <div className="resume-item">
                    <h4>Bachelor of Science and Humanities</h4>
                    <h5>Aug 2006 - June 2016</h5>
                    <p><em>Nashville School, Tegucigalpa, HND</em></p>
                    <p>Elementary, Middle and High School. Graduated from Nashville School Tegucigalpa with a bachelor degree in sciences and humanities.</p>
                  </div>
                  <div className="resume-item">
                    <h4>Pre-school and Basic Education</h4>
                    <h5>Aug 2002 - Jun 2006</h5>
                    <p><em>International School, Tegucigalpa, HND</em></p>
                    <p>Preschool and part of Elementary School. Moved on later to Nashville School.</p>
                  </div>
                  <h3 className="resume-title">Certifications</h3>
                  <div className="resume-item">
                    <ul>
                      <li>CCNA1</li>
                      <li>CCNA2</li>
                    </ul>
                  </div>
                  <h3 className="resume-title">Volunteering</h3>
                  <div className="resume-item">
                    <h4>Honducamp.Tech</h4>
                    <h5>2018 - 2018</h5>
                    <p><em>Tegucigalpa, HND</em></p>
                  </div>
                  <div className="resume-item">
                    <h4>Hogar de Ancianos Salvador Aguirre</h4>
                    <h5>Jan 31 2020 - March 27 2020</h5>
                    <p><em>Tegucigalpa, HND</em></p>
                  </div>
                  <div className="resume-item">
                    <h4>Asociación China de Honduras</h4>
                    <p><em>Tegucigalpa, HND</em></p>
                  </div>
                  <h3 className="resume-title">Languages</h3>
                  <div className="resume-item">
                    <ul>
                      <li>Spanish(Main)</li>
                      <li>English</li>
                    </ul>
                  </div>
                  <h3 className="resume-title">Personal Experience/Projects</h3>
                  <div className="resume-item">
                    <ul>
                      <li>Creation of interface for multiple Landing Pages for university projects.</li>
                      <li>Develop different software in the area of ​​commerce for small and medium-sized businesses, companies (among them Barbería Jazmín, Empeños Anderson).</li>
                      <li>Development of reservation software (reservations for laboratories and rooms at UNICAH).</li>
                      <li>Application of ISO 9001-2015 to small and medium-sized companies.</li>
                      <li>Game software development in C# and Java.</li>
                      <li>Creation of a portable game with Arduino (snake).</li>
                      <li>White box and black box testing to software.</li>
                      <li>Projects carried out with Multisim on circuit design.</li>
                      <li>Development of bots for Discord servers(discord bot).</li>
                      <li>Development of game scripts in C#.</li>
                      <li>Personal Website/Page.</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <h3 className="resume-title">Professional Experience</h3>
                  <div className="resume-item">
                    <h4>Software/Web Developer</h4>
                    <h5>June 2021 - Present</h5>
                    <p><em>Creative Productions, Tegucigalpa, HND </em></p>
                    <p>
                    </p>
                    <ul>
                      <li>Mobile application (Android), for the IHT (Honduran Tourism Institute). What was used: Ionic Framework, AngularJS, Typescript, HTML, SCSS.
                        <ul>
                          <li>Implementation of routes on map using Google Maps api.</li>
                          <li>Realtime Chat.</li>
                          <li>Google Analytics implementation(screenviews, events log, etc.).</li>
                          <li>Accessibility and management of users and premises(business) on the app.</li>
                        </ul>
                      </li>
                      <li>Web page. Dashboard for managing premises, routes, users within the mobile application also integration of data metrics(own dashboarb with personalized metrics and dimensions) extracted from and with Google Analytics Api from data collected by the mobile app. Developed with: VueJS.</li>
                      <li>Mobile App(Android).
                        <ul>
                          <li>QR code and barcodes scanner and generator.</li>
                        </ul>
                      </li>
                      <li>Ticketing Software developed with ElectronJS (with jQuery).
                        <ul>
                          <li>Full control of tickets (creation, deletion, reservation) for events.</li>
                          <li>Creation of maps (seats for reservation) and templates for tickets.</li>
                          <li>Ticket metrics.</li>
                          <li>Control of types of users and their access and restrictions.</li>
                        </ul>
                      </li>
                      <li>Mobile application (IOS). Web page container app. Developed with: Ionic Framework, Capacitor, Xcode + publishing app to the Apple Store.</li>
                      <li>Web Hosting Management. SSL, DNS/CDN, DNSEC, Cloudflare DNS of multiple sites</li>
                      <li>Web Hosting Migration (Websites, Emails (Migrate emails to gmail, and SMTP & POP3/IMAP configurations)).</li>
                    </ul>
                    <p />
                  </div>
                  <div className="resume-item">
                    <h4>Project Technician</h4>
                    <h5>Nov 8 2021 - Dec 7 2021</h5>
                    <p><em>IMSA S. de R.L. de C.V (Inversiones Múltiples Salas Sociedad de Responsabilidad Limitada de Capital Variable), Tegucigalpa, HND</em></p>
                    <p>
                    </p>
                    <ul>
                      <li>White box tests of the software to be used for the transmission of preliminary elections results(TREP).</li>
                      <li>Hardware tests (laptops, printers, modems, hubs, power generators, antennas) to be used with TREP software.</li>
                      <li>Training people on the management of hardware and software to used in addition to the possible problems that can present and their solutions.</li>
                      <li>Call Center Support. Provide help to trained Call Center agents on problems with the TREP system beyond its scope.</li>
                      <li>Technician in the scrutiny process.</li>
                    </ul>
                    <p />
                  </div>
                  <div className="resume-item">
                    <h4>Merchant</h4>
                    <h5>Jan 2009 - Present</h5>
                    <p><em>Mercadito Francisco/Cho, Tegucigalpa, HND</em></p>
                    <p>
                    </p>
                    <ul>
                      <li>Identifying trends and market opportunities.</li>
                      <li>Sourcing and negotiating with suppliers.</li>
                      <li>Managing inventory levels and stock replenishment.</li>
                      <li>Setting prices and promotions.</li>
                      <li>Managing financial and accounting aspects of the business.</li>
                    </ul>
                    <p />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>{/* End About Section */}


        {/* ======= Download CV Modal ======= */}
        {this.state.showLoading ? <Loadingcomp /> : null}


        {/* ======= Download CV Modal ======= */}
        <div>
          <Modal show={this.state.isOpen} onHide={this.closeModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeModal}>Close</Button>
              <Button variant="primary">Save changes</Button>
            </Modal.Footer>
          </Modal>
        </div>

      </>
    )
  }


  async downloadCV() {
    let hidediv1 = document.getElementById('counts');
    this.setState({ showLoading: true });
    hidediv1.style.display = 'none'

    let scaleimg = 1.5;
    var container = document.querySelector("#cvdiv");
    window.html2canvas(container, { scale: scaleimg, allowTaint: true }).then(canvas => {
      let doc = new window.jsPDF({ orientation: 'p', unit: 'px', format: 'letter', compress: true });
      doc.width = doc.internal.pageSize.width;
      doc.height = doc.internal.pageSize.height;
      doc.margin = { horiz: 10, vert: 10 };
      doc.work = {
        width: doc.width - (doc.margin.horiz * 2),
        height: doc.height - (doc.margin.vert * 2)
      }

      let data = {
        width: container.offsetWidth * scaleimg,
        height: container.offsetHeight * scaleimg,
        ctx: canvas.getContext('2d', { willReadFrequently: true }),
        page: {}
      };

      data.page.width = data.width;
      data.page.height = (data.width * doc.work.height) / doc.work.width;

      const getData = function (imgData, width, height) {
        let oCanvas = document.createElement('canvas');
        oCanvas.width = width;
        oCanvas.height = height;
        let oCtx = oCanvas.getContext('2d', { willReadFrequently: true });
        oCtx.putImageData(imgData, 0, 0);
        return oCanvas.toDataURL('image/png');
      };

      let oImgData = null;
      let dataURL = null;
      let pages = Math.ceil(data.height / data.page.height);

      for (let i = 0; i < pages; i++) {
        if (i !== 0) {
          doc.addPage();
        }
        oImgData = data.ctx.getImageData(0, data.page.height * i, data.page.width, data.page.height);
        dataURL = getData(oImgData, data.page.width, data.page.height);
        doc.addImage(dataURL, 'PNG', doc.margin.horiz, doc.margin.vert, doc.work.width, doc.work.height, '', 'FAST');
      }

      doc.save('LuisChuyCV.pdf');
      hidediv1.style.display = '';
      this.setState({ showLoading: false });
    });

  }

  switchModes() {
    let colormodestrge = localStorage.getItem('colormode');

    // eslint-disable-next-line
    if (colormodestrge  == 'lightmode') {
      localStorage.setItem('colormode', 'darkmode');
      window.dispatchEvent(new Event('storage'))
    } else {
      localStorage.setItem('colormode', 'lightmode');
      window.dispatchEvent(new Event('storage'))
    }
  }


  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  toggleModal() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  openLoading() {
    this.setState({ showLoading: true });
    setTimeout(() => {
      this.setState({ showLoading: false });
    }, 5000);
  }

  componentDidMount() {
    this.changesonstorage(); 
    window.addEventListener("storage", this.changesonstorage.bind(this), false);
  }
  componentWillUnmount() {
    window.removeEventListener("storage", this.left.bind(this), false);
  }

  changesonstorage() {
    let changetest = localStorage.getItem('colormode');
    var aboutctn = document.getElementById("cvdiv");

    // eslint-disable-next-line
    if (changetest == 'lightmode') {
      aboutctn.classList.add("lightmode-aboutme")
      aboutctn.classList.remove("darkmode-aboutme");
    } else {
      aboutctn.classList.add("darkmode-aboutme")
      aboutctn.classList.remove("lightmode-aboutme");
    }
  }

  left() {
    console.log('removedlistener aboutme poage')
  }

}

export default AboutmeEN