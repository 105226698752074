import React, { Component } from 'react'
import '../assets/css/Aboutme.css'

export class Aboutme extends Component {
  render() {
    return (
      <>
        <div className="raindroplines backgroundpageabtme">
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
        </div>
      </>
    )
  }
}

export default Aboutme