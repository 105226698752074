import React, { Component } from 'react'
import '../assets/css/Home.css'
import { Link } from "react-router-dom";
import {ReactComponent as HexagonLight} from '../assets/icons/HexagonLight.svg'
import {ReactComponent as HexagonDark} from '../assets/icons/HexagonDark.svg'

export class Home extends Component {
  state = {
    HexaDarkMode: true,
  };

  render() {
    return (
      <>
        <div className="pt-table">
          <div className="pt-tablecell page-home ">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-offset-1 col-md-10 col-lg-offset-2mod col-lg-8mod">
                  <div className="mb-5 text-center">
                    <p className="home_title .darkcolortxt2"> Welcome to My Page</p>
                    <p className="mt20 home_subtitle .darkcolortxt2">Under Development. When I have time I work on it otherwise I'm just procrastinating on whatever I'm doing. Actually just me lazy as always...</p>
                  </div>
                  <div className="hexagon-menu cleardivbelowhome">
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div />
                        <div />
                        <div />
                      </div>
                      <div className="hex-item">
                        <div />
                        <div />
                        <div />
                      </div>
                      <Link className="hex-content .lightcolortxt">
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa-solid fa-universal-access" />
                          </span>
                          <span className="title">Welcome</span>
                        </span>
                        {this.state.HexaDarkMode ? <HexagonLight /> : <HexagonDark />}
                      </Link>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div />
                        <div />
                        <div />
                      </div>
                      <div className="hex-item">
                        <div />
                        <div />
                        <div />
                      </div>
                      <Link className="hex-content .lightcolortxt" to='/AboutmeEN'>
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-bullseye" />
                          </span>
                          <span className="title">About</span>
                        </span>
                        {this.state.HexaDarkMode ? <HexagonLight /> : <HexagonDark />}
                      </Link>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div />
                        <div />
                        <div />
                      </div>
                      <div className="hex-item">
                        <div />
                        <div />
                        <div />
                      </div>
                      <Link className="hex-content .lightcolortxt">
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-braille" />
                          </span>
                          <span className="title">Services</span>
                        </span>
                        {this.state.HexaDarkMode ? <HexagonLight /> : <HexagonDark />}
                      </Link>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div />
                        <div />
                        <div />
                      </div>
                      <div className="hex-item">
                        <div />
                        <div />
                        <div />
                      </div>
                      <Link className="hex-content .lightcolortxt" to='/AboutmeEN'>
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-id-badge" />
                          </span>
                          <span className="title">Resume/CV</span>
                        </span>
                        {this.state.HexaDarkMode ? <HexagonLight /> : <HexagonDark />}
                      </Link>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div />
                        <div />
                        <div />
                      </div>
                      <div className="hex-item">
                        <div />
                        <div />
                        <div />
                      </div>
                      <Link className="hex-content .lightcolortxt">
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-life-ring" />
                          </span>
                          <span className="title">Works</span>
                        </span>
                        {this.state.HexaDarkMode ? <HexagonLight /> : <HexagonDark />}
                      </Link>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div />
                        <div />
                        <div />
                      </div>
                      <div className="hex-item">
                        <div />
                        <div />
                        <div />
                      </div>
                      <Link className="hex-content .lightcolortxt">
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-clipboard" />
                          </span>
                          <span className="title">Idk</span>
                        </span>
                        {this.state.HexaDarkMode ? <HexagonLight /> : <HexagonDark />}
                      </Link>
                    </div>
                    <div className="hexagon-item">
                      <div className="hex-item">
                        <div />
                        <div />
                        <div />
                      </div>
                      <div className="hex-item">
                        <div />
                        <div />
                        <div />
                      </div>
                      <Link className="hex-content .lightcolortxt">
                        <span className="hex-content-inner">
                          <span className="icon">
                            <i className="fa fa-map-signs" />
                          </span>
                          <span className="title">Contact</span>
                        </span>
                        {this.state.HexaDarkMode ? <HexagonLight /> : <HexagonDark />}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  componentDidMount() {
    this.changesonstorage();
    window.addEventListener("storage", this.changesonstorage.bind(this), false);
  }
  componentWillUnmount() {
    window.removeEventListener("storage", this.left.bind(this), false);
  }

  left(){
  }

  changesonstorage() {
    let test = localStorage.getItem('colormode');
    var allhexagons = document.querySelectorAll(".hex-content")
    var alltexthome = document.querySelectorAll(".home_subtitle, .home_title")

    // eslint-disable-next-line
    if (test == 'lightmode') {
      this.setState({ HexaDarkMode: false });
      allhexagons.forEach(hexagon => {
        hexagon.classList.add("darkcolortxt")
        hexagon.classList.remove("lightcolortxt");
      })
      alltexthome.forEach(txt => {
        txt.classList.add("lightcolortxt2")
        txt.classList.remove("darkcolortxt2");
      })
    } else {
      this.setState({ HexaDarkMode: true });
      allhexagons.forEach(hexagon => {
        hexagon.classList.add("lightcolortxt")
        hexagon.classList.remove("darkcolortxt");
      })
      alltexthome.forEach(txt => {
        txt.classList.add("darkcolortxt2")
        txt.classList.remove("lightcolortxt2");
      })
    }
  }
}

export default Home