import React, { Component } from 'react'
import '../assets/css/Navbarcomp.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from "react-router-dom";
import pandalogo from '../assets/icons/PandaFaceLogo.svg'

export class Navbarcomp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsesize: 'lg',
      showchanvas: false
    }
  }

  closeOffCanvas() {
    this.setState({ showchanvas: false })
  }

  changeColorMode() {
    let test = localStorage.getItem('colormode');

    // eslint-disable-next-line
    if (test == 'lightmode') {
      localStorage.setItem('colormode', 'darkmode');
      window.dispatchEvent(new Event('storage'))
    } else {
      localStorage.setItem('colormode', 'lightmode');
      window.dispatchEvent(new Event('storage'))
    }
  }

  render() {
    return (
      <>
        <Navbar collapseOnSelect className='color-nav' variant="light" expand={this.state.collapsesize}>
          <Container fluid>
            <Navbar.Brand as={Link} to="/">
              <img src={pandalogo} width="30" height="30" className="d-inline-block align-top" alt='' />
              <span className='navbarbrandname'>CHUYCHO</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${this.state.collapsesize}`} onClick={() => { this.setState({ showchanvas: true }) }}>
              {/*<i className="bi bi-three-dots"></i>*/}
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${this.state.collapsesize}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${this.state.collapsesize}`}
              placement="end"
              show={this.state.showchanvas}
              onHide={() => this.closeOffCanvas()}
              restoreFocus={false}
              className='offcanvascss'
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${this.state.collapsesize}`}>
                  Portafolio
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {/*<Link className="nav-link" to="/contact"></Link>*/}
                  <Nav.Link as={Link} to="/" onClick={() => this.closeOffCanvas()}>Home</Nav.Link>
                  <Nav.Link as={Link} to="/portafolio" onClick={() => this.closeOffCanvas()}>Portafolio</Nav.Link>
                  <Nav.Link as={Link} to="/AboutmeEN" onClick={() => this.closeOffCanvas()}>About</Nav.Link>
                  <NavDropdown title="Resume/CV" id={`offcanvasNavbarDropdown-expand-${this.state.collapsesize}`}>
                    <NavDropdown.Item as={Link} to="/AboutmeEN" onClick={() => this.closeOffCanvas()}>English</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/AboutmeEN" onClick={() => this.closeOffCanvas()}>Spanish</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Idk" id={`offcanvasNavbarDropdown-expand-${this.state.collapsesize}`}>
                    <NavDropdown.Item as={Link} to="/aboutme" onClick={() => this.closeOffCanvas()}>Rain</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/works" onClick={() => this.closeOffCanvas()}>Flip Test</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/aboutme" onClick={() => this.closeOffCanvas()}>Another action</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/login" onClick={() => this.closeOffCanvas()}>Login</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/signup" onClick={() => this.closeOffCanvas()}>Sign Up</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item as={Link} to="/aboutme" onClick={() => this.closeOffCanvas()}>Something else here</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link onClick={() => this.closeOffCanvas()}>asdasdadsad</Nav.Link>
                  <Nav.Link onClick={() => {this.closeOffCanvas(); this.changeColorMode()}} style={{ fontSize: '25px', padding: 0 }}><span title='Change Color'>&#9728;</span></Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </>
    )
  }
}

export default Navbarcomp